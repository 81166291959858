/**
 *
 * @param date
 * @param locale
 * @returns The current date in the format "day month"
 *
 * @example
 * dateToString(new Date(), "en-US"); // "1 January"
 */
export default function dateToString(
  date: Date | string,
  locale: string
): string {
  if (typeof date === "string") {
    date = new Date(date);
  }

  return date.toLocaleDateString(locale, {
    day: "numeric",
    month: "long",
  });
}
