import {
  BrochureImpressionSource,
  BrochureVisitSource,
} from "./../graphql/generated/index";
// Type safe useState for impressionsource
const VISIT_SOURCE = "impressionsource";

export default function useVisitSource() {
  const state = useState<BrochureVisitSource>(
    VISIT_SOURCE,
    () => BrochureVisitSource.Unknown
  );

  // When navigating to a brochure from a tile, convert the impression source to a visit source
  const setVisitSource = (source: BrochureImpressionSource) => {
    switch (source) {
      case BrochureImpressionSource.AlsoInteresting:
        state.value = BrochureVisitSource.AlsoInteresting;
        break;
      case BrochureImpressionSource.Category:
        state.value = BrochureVisitSource.Category;
        break;
      case BrochureImpressionSource.Favorites:
        state.value = BrochureVisitSource.Favorites;
        break;
      case BrochureImpressionSource.NewOpen:
        state.value = BrochureVisitSource.NewOpen;
        break;
      case BrochureImpressionSource.Search:
        state.value = BrochureVisitSource.Search;
        break;
      case BrochureImpressionSource.Shop:
        state.value = BrochureVisitSource.Shop;
        break;
      case BrochureImpressionSource.Brand:
        state.value = BrochureVisitSource.Brand;
        break;
      case BrochureImpressionSource.City:
        state.value = BrochureVisitSource.City;
        break;
      default:
        state.value = BrochureVisitSource.Unknown;
    }
  };

  watch(
    () => isEmbedded(),
    (isEmbed) => {
      if (isEmbed) {
        state.value = BrochureVisitSource.Embed;
      } else if (state.value === BrochureVisitSource.Embed) {
        // If we are not embedded, but still have that as the source, set it back to unknown
        state.value = BrochureVisitSource.Unknown;
      }
    },
    { immediate: true }
  );

  const visitSource = readonly(state);

  return { visitSource, setVisitSource };
}
