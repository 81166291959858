<template>
  <NuxtLink
    ref="brochureItem"
    class="brochure-item"
    :to="
      localePath({
        name: 'slug-offers-id-pagenumber',
        params: { slug: brochure.shop.slug, id: brochure.id, pagenumber: '0' },
      })
    "
    @click="setVisitSource(impressionSource)"
  >
    <div class="brochure-item-cover">
      <BaseImageExternal
        v-if="brochure.cover.fileUrl"
        class="brochure-cover"
        :src="brochure.cover.fileUrl"
        :alt="$t('brochure.cover-alt', [brochure.name])"
        :aspect-ratio="brochure.cover.imageRatio"
      />
    </div>
    <div class="brochure-item-info">
      <BaseText class="card-title">{{ brochure.name }}</BaseText>
      <BrochureDate
        :expire-after="brochure.expireAfter"
        :active-from="brochure.activeFrom"
        class="card-subtitle"
      />
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import {
  type BrochureFragment,
  BrochureImpressionSource,
  useCreateBrochureImpressionStatisticMutation,
  type BrochureSearchResultFragment,
} from "~/graphql/generated";

const props = defineProps<{
  brochure: BrochureFragment | BrochureSearchResultFragment;
  impressionSource: BrochureImpressionSource;
}>();

const localePath = useLocalePath();

// Statistics mutations
const createBrochureImpression = useCreateBrochureImpressionStatisticMutation();

// Log the impression when the item is visible
const brochureItem = ref<HTMLAnchorElement>();
const isBrochureItemVisible = useElementVisibility(brochureItem);
const deviceId = useDeviceId();

watch(
  isBrochureItemVisible,
  (isVisible) => {
    if (isVisible) {
      createBrochureImpression.executeMutation({
        createBrochureImpressionStatistic: {
          brochureId: props.brochure.id,
          source: props.impressionSource,
          deviceId: deviceId.value,
        },
      });
    }
  },
  { immediate: true }
);

// Set the impression state, that we use for the brochure visit statistic in the brochure viewer
const { setVisitSource } = useVisitSource();
</script>

<style lang="scss" scoped>
.brochure-item {
  max-width: 520px;
  background-color: $light-95;
  border: 1px solid $light-80;
  overflow: hidden;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .brochure-item-cover {
    flex: 1;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color $easing;
  }

  &:hover .brochure-item-cover {
    background-color: $light-80;
  }

  .brochure-item-info {
    background-color: $light-100;
    margin-top: auto;
    padding: 0.5rem;

    .card-title {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
