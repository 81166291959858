<template>
  <BaseText>
    <ClientOnly>
      <template v-if="activeFromString">
        {{ $t("home.active-from", [activeFromString]) }}
      </template>
      <template v-else>
        {{ $t("home.expires-after", [expiresAtString]) }}
      </template>
    </ClientOnly>
    &nbsp;
    <!-- So the page won't jump -->
  </BaseText>
</template>

<script setup lang="ts">
const props = defineProps<{
  activeFrom: string;
  expireAfter: string;
}>();

const { t } = useI18n();
const { locale } = useI18n();

const now = new Date();

// Format a nice string when the brochure will be active (only if not active yet)
const dateActive = new Date(props.activeFrom);
const activeFromString = computed(() => {
  if (!process.client) return "";
  if (dateActive.getTime() > now.getTime()) {
    const daysDiff =
      (dateActive.getTime() - now.getTime()) / (24 * 60 * 60 * 1000);

    // If the brochure becomes active today, we return 'today'
    if (daysDiff < 1) {
      // Check if the dates are the same
      const normalizedDateActive = new Date(dateActive).setHours(0, 0, 0, 0);
      const normalizedNow = new Date(now).setHours(0, 0, 0, 0);

      if (normalizedDateActive === normalizedNow) {
        return t("home.today");
      }
    }

    // If it becomes active in the next week, we return the weekday
    if (daysDiff < 6) return t(`home.days.${dateActive.getDay()}`);

    // Else, format the month and day
    dateToString(dateActive, locale.value);
  }

  return null;
});

// Format a nice string depending on when the brochure expires
const dateExpires = new Date(props.expireAfter);
const expiresAtString = computed(() => {
  if (!process.client) return "";
  const daysDiff = Math.round(
    (dateExpires.getTime() - now.getTime()) / (24 * 60 * 60 * 1000)
  );

  // If the brochure expires today, we return 'today'
  if (daysDiff === 0) return t("home.today");

  // If it expires in the next week, we return the weekday
  if (daysDiff < 6) return t(`home.days.${dateExpires.getDay()}`);

  // Else, return the date formatted in the users browser settings
  return dateToString(dateExpires, locale.value);
});
</script>

<style lang="scss" scoped></style>
